import { Injectable } from '@angular/core'
import { DailyMenuService } from '@app/services/daily-menu/daily-menu.service'
import {
    AdditionResolveFailure,
    AdditionResolveResult,
    AdditionResolveSuccess,
    Cart,
    CartItem,
    CartItemResolveFailure,
    CartItemResolveResult,
    CartItemResolveSuccess,
    CartResolveResult,
} from '@app/services/cart/cart.types'
import { always, isNil, none } from 'ramda'
import { RestaurantsService } from '@app/services/restaurants/restaurants.service'
import { DailyMenuItemFragment, RestaurantFragment } from '@app-graphql/api-schema'
import { cached, minutes, seconds } from '@app/decorators/method/cached.decorator'
import { transformRecord } from '@lib/common.lib'
import { CapacityService } from '@app/services/capacity/capacity.service'

@Injectable({
    providedIn: 'root',
})
export class CartResolverService {
    constructor(
        private readonly menuItemsService: DailyMenuService,
        private readonly restaurantsService: RestaurantsService,
        private readonly capacityService: CapacityService,
    ) {
    }

    /**
     * Attempts to convert the given {@link Cart} into its {@link ResolvedCart resolved counterpart}
     * suitable for order processing. Returns either the {@link ResolvedCart fully resolved type} or a
     * {@link PartiallyResolvedCart partially resolved type} discriminated by the `resolveError` boolean
     * property. This makes sure that a restaurant exists by the contained ID, that all menu-items exist and
     * if they have additions, that those are available for the respective items.
     */
    public async resolve(cart: Cart): Promise<CartResolveResult> {
        const { key, orderDeadline, transferDate, items: cartItems } = cart

        const [restaurant, items] = await Promise.all([
            this.resolveRestaurant(cart.restaurantId),
            transformRecord((item: CartItem) => this.resolveCartItem(item))(cartItems),
        ])

        return this.itemsAreResolved(items) && ! isNil(restaurant)
            ? { key, restaurant, orderDeadline, transferDate, items, resolveError: false }
            : { key, restaurant, orderDeadline, transferDate, items, resolveError: true, source: cart }
    }

    // ------------------------------------------------------------------------------
    //      Private implementation
    // ------------------------------------------------------------------------------

    private async resolveCartItem(item: CartItem): Promise<CartItemResolveResult> {
        const menuItem = await this.resolveMenuItem(item.menuEntryOccurrenceId)

        const { key, additions: additionSnapshots, quantity } = item

        const additions: AdditionResolveResult[] = []

        for (const snapshot of additionSnapshots) {
            const addition = menuItem?.additions.find((x) => x.id === snapshot.id)
            additions.push(
                addition
                    ? <AdditionResolveSuccess>{ TAG: 'AdditionResolveSuccess', resolveError: false, fragment: addition }
                    : <AdditionResolveFailure>{ TAG: 'AdditionResolveFailure', resolveError: true, snapshot },
            )
        }

        return isNil(menuItem) || ! this.additionsAreResolved(additions)
            ? { resolveError: true, ...item, additions }
            : { resolveError: false, quantity, key, menuItem, additions }
    }

    @cached<[string]>({
        TTL: minutes(10),
    })
    private async resolveRestaurant(id: string): Promise<RestaurantFragment | null> {
        return this.restaurantsService.getRestaurantById(id, true).catch(always(null))
    }

    @cached<[string]>({
        TTL: seconds(5),
    })
    private async resolveMenuItem(id: string): Promise<DailyMenuItemFragment | null> {
        try {
            const item = await this.menuItemsService.getDailyMenuItem(id, true)
            this.capacityService.registerInitialStock(item)
            return item
        } catch (error: unknown) {
            return null
        }
    }

    // ------------------------------------------------------------------------------
    //      Utilities
    // ------------------------------------------------------------------------------

    private itemsAreResolved(
        items: Record<string, CartItemResolveResult>): items is Record<string, CartItemResolveSuccess> {
        return none((item): item is CartItemResolveFailure => item.resolveError, Object.values(items))
    }

    private additionsAreResolved(additions: AdditionResolveResult[]): additions is AdditionResolveSuccess[] {
        return none((addition) => addition.resolveError, additions)
    }
}
