import { GraphQLError } from 'graphql'
import { path } from 'ramda'

export class RegistrationError extends Error {
    constructor(public readonly previous: GraphQLError) {
        super(previous.message)
    }

    public isValidationError(): boolean {
        return this.previous.extensions && 'validation' in this.previous.extensions
    }

    public getValidationMessage(): string | undefined {
        return path(['extensions', 'validation', 0, 0], this.previous)
    }
}
