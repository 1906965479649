import { Injectable } from '@angular/core'
import { CartService } from '@app/services/cart/cart.service'
import { CheckoutInput } from '@app/services/checkout/checkout-input/checkout-input.types'
import { BehaviorSubject, Observable } from 'rxjs'
import { isNil, mergeRight, prop } from 'ramda'
import { map } from 'rxjs/operators'
import { Storage } from '@ionic/storage'
import { distinctUntilChangedEquals } from '@lib/rxjs/rxjs.lib'
import type { RequiresInitialization } from '@app/types/framework.types'
import { Fn1 } from '@lib/functions/functions.lib'

@Injectable({
    providedIn: 'root',
})
export class CheckoutInputService implements RequiresInitialization {
    private readonly checkoutInputStorageKey = 'checkout:input'
    private readonly checkoutInputSubject = new BehaviorSubject<CheckoutInput.InputRecord>({})

    constructor(
        private readonly storage: Storage,
        private readonly cartService: CartService,
    ) {
    }

    public async initialize(): Promise<void> {
        const storedInput: CheckoutInput.InputRecord = await this.storage.get(this.checkoutInputStorageKey) ?? {}
        this.checkoutInputSubject.next(storedInput)
        this.cartService.watchCartKeys().subscribe((keys) => this.handleCartChanges(keys))
    }

    // ------------------------------------------------------------------------------
    //      Checkout input state
    // ------------------------------------------------------------------------------

    /**
     * Gets the {@link CheckoutInput.ForCart checkout input} for the cart by given key.
     *
     * This overload `strict: true` throws if there is no input set.
     */
    public getCheckoutInput(cartKey: string, strict: true): CheckoutInput.ForCart
    /**
     * Gets the {@link CheckoutInput.ForCart checkout input} for the cart by given key.
     *
     * This overload `strict?: false` returns `undefined` if there is no input set.
     */
    public getCheckoutInput(cartKey: string, strict?: false): CheckoutInput.ForCart | undefined
    public getCheckoutInput(cartKey: string, strict = false): CheckoutInput.ForCart | undefined {
        const input = this.checkoutInputSubject.getValue()[cartKey]

        if (isNil(input) && strict) {
            throw new Error(`Failed to get checkout input for cart ${cartKey}`)
        }

        return input
    }

    /**
     * Watches the checkout input for given cart-key. The returned observable emits either the
     * current input or `undefined` when no input exists for the given cart-key.
     */
    public watchCheckoutInput(cartKey: string): Observable<CheckoutInput.ForCart | undefined> {
        return this.checkoutInputSubject.pipe(
            map(prop(cartKey)),
            distinctUntilChangedEquals(),
        )
    }

    /**
     * Takes a cart key and a transformer function to patch the checkout input for the cart by given key
     * with. The transformer is given the current checkout input if it exists, or a new empty input otherwise.
     * It should return the new {@link CheckoutInput.ForCart checkout input} object for the cart.
     */
    public async patchInput(cartKey: string, transformer: Fn1<CheckoutInput.ForCart>): Promise<void> {
        const inputRecord = this.checkoutInputSubject.getValue()

        const prevInput = inputRecord[cartKey]
        const nextInput = transformer(prevInput ?? this.createEmptyInput(cartKey))

        await this.submitCheckoutInput(
            mergeRight(inputRecord, { [cartKey]: nextInput }),
        )
    }

    /**
     * Deletes all the current checkout input for all carts.
     */
    public async clearAllCheckoutInput(): Promise<void> {
        await this.submitCheckoutInput({})
    }

    public createEmptyInput(cartKey: string): CheckoutInput.ForCart {
        return {
            cartKey: cartKey,
            timeslot: null,
            address: null,
        }
    }

    private async handleCartChanges(keys: readonly string[]): Promise<void> {
        const prevInput: CheckoutInput.InputRecord = this.checkoutInputSubject.getValue()
        const nextInput: CheckoutInput.InputRecord = {}

        for (const key of keys) {
            nextInput[key] = prevInput[key] ?? this.createEmptyInput(key)
        }

        await this.submitCheckoutInput(nextInput)
    }

    private async submitCheckoutInput(data: CheckoutInput.InputRecord): Promise<void> {
        await this.storage.set(this.checkoutInputStorageKey, data)
        this.checkoutInputSubject.next(data)
    }
}
