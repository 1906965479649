import { isEmpty, Ord } from 'ramda'
import { Fn1, TRUE } from '@lib/functions/functions.lib'

export function filterMap<T, U>(list: readonly T[], fnFilter: (x: T) => boolean, fnMap: (x: T) => U): U[] {
    return list.reduce((acc, item) => {
        if (fnFilter(item)) acc.push(fnMap(item))
        return acc
    }, [] as U[])
}

export function sumBy<T extends object>(
    numberFn: Fn1<T, number>,
    filterFn: Fn1<T, boolean> = TRUE,
): Fn1<readonly T[], number> {
    return (xs) => xs.reduce((sum, x) => filterFn(x) ? sum + numberFn(x) : sum, 0)
}

export function maximumBy<T>(fn: (x: T) => Ord): (xs: readonly T[]) => T | undefined {
    return (xss) => {
        if (isEmpty(xss)) {
            return undefined
        }

        const [x, ...xs] = xss

        let maxX: T = x
        let maxOrd: Ord = fn(x)

        for (const xNext of xs) {
            const xNextOrd = fn(xNext)
            if (xNextOrd >= maxOrd) {
                maxOrd = xNextOrd
                maxX = xNext
            }
        }

        return maxX
    }
}
