/**
 * The function that always returns true.
 */
export const TRUE = (): true => true

/**
 * The function that always returns false.
 */
export const FALSE = (): false => false

/**
 * The function that does nothing and returns nothing.
 */
export const noop = () => undefined

// ------------------------------------------------------------------------------
//      Function type wrappers
// ------------------------------------------------------------------------------
export type Fn1<T, R = T> = (x: T) => R
export type Fn2<T1, T2, R> = (x: T1, y: T2) => R
export type Fn3<T1, T2, T3, R> = (x: T1, y: T2, z: T3) => R

export type Pred<T> = Fn1<T, boolean>
export type Pred2<T1, T2> = Fn2<T1, T2, boolean>
export type Pred3<T1, T2, T3> = Fn3<T1, T2, T3, boolean>

/**
 * Type alias for an argument-less factory function of type T.
 */
export type Fact<T> = () => T
