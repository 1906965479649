import { andThen, fromPairs, map, pipe, toPairs } from 'ramda'
import type { Await } from '@app/types/common.types'
import { Fn1 } from '@lib/functions/functions.lib'

/**
 * A function that does nothing. Can be safely passed to where an impure function is expected,
 * but which should have no effects.
 */
export function noop(): void {
    // does nothing...
}

// ------------------------------------------------------------------------------
//      Error casting
// ------------------------------------------------------------------------------

/**
 * Normalizes an unknown error type to a string for error reporting.
 */
export function errorMessage(error: unknown): string {
    return error instanceof Error ? error.message : String(error)
}

export function delay<T>(ms: number, value: T): Promise<T>
export function delay(ms: number): Promise<void>
export function delay(ms: number, value = undefined): Promise<any> {
    return new Promise((resolve) => setTimeout(() => resolve(value), ms))
}

/**
 * Takes a transformer function `T => U` and returns a new function that takes a `Record<K, T>`.
 * When called, the function returns a promise of a record with the **awaited** values of `U`.
 * This means that the transformer may return promises, and they will all be awaited so that one
 * single promise for a `Record<K, Await<U>>` can be returned.
 */
export function transformRecord<
    T,
    U,
    K extends string = string,
>(transformer: Fn1<T, U>): (record: Record<K, T>) => Promise<Record<K, Await<U>>> {
    /* eslint-disable @typescript-eslint/indent */
    return pipe<
        [Record<K, T>],
        [string, T][],
        Promise<[string, Await<U>]>[],
        Promise<[string, Await<U>][]>,
        Promise<Record<string, Await<U>>>
    >(
        toPairs,
        map(async ([key, value]) => [key, await transformer(value)] as [K, Await<U>]),
        Promise.all,
        andThen(fromPairs),
    )
    /* eslint-enable @typescript-eslint/indent */
}
